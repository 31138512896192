/* src/css/Conferences.css */
.conference-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
    padding: 0;
  }
  
  .conference-item {
    padding: 0;
  }
  
  .conference-link {
    display: block;
    font-family: "Source Serif Pro", serif;
    padding: 1rem;
    border-radius: 8px;
    color: rgb(96, 96, 96);
    transition: background-color 0.3s ease;
    text-decoration: none;
  }
  
  .conference-link:hover {
    background-color: #e0e0e0;
    color: #333;
  }
  
  .conference-submitted {
    background-color: rgb(175, 198, 217);
  }
  
  .conference-journal {
    background-color: rgb(226, 146, 146);
  }
  
  .conference-conference {
    background-color: rgb(232, 201, 98);
  }
  
  .conference-misc {
    background-color: rgb(193, 224, 206);
  }
  
  .conference-section {
    margin-bottom: 64px;
    padding-left: 50px;
    padding-right: 50px;
  }
  
  .conference-section h2 {
    margin-bottom: 14px;
    font-family: "Source Serif Pro", serif;
    font-size: 24px;
    text-align: center;
    font-weight: 400;
  }
  
  .conference-section ul {
    list-style-type: decimal;
    overflow-wrap: break-word;
    text-align: left;
    padding-left: 0;
  }
  
  .conference-section ul li {
    line-height: 27.2px;
    font-size: 16px;
  }
  
  .conference-section ul li a {
    text-decoration: underline;
  }
  
  @media (max-width: 576px) {
    .conference-nav .col-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .conference-section {
      padding-left: 0;
      padding-right: 0;
    }
  }
  
  @media (min-width: 577px) and (max-width: 992px) {
    .conference-nav .col-sm-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  
  @media (min-width: 993px) {
    .conference-nav .col-lg-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  