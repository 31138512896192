/* src/css/Header.css */
.header-container {
    background-color: #f8f9fa;
    padding: 25px 0;
    margin-top: 20px;
  }
  
  .header-title {
    font-size: 2.5rem;
    font-weight: 600; /* Применение жирного начертания */
    margin: 0;
    font-family: 'Source Serif Pro', serif; /* Использование нового шрифта */
  }
  
  .header-title-link {
    text-decoration: none;
    color: #000;
  }
  
  .header-title-link:hover {
    text-decoration: underline;
  }
  
  .header-subtitle {
    font-size: 0.9rem;
    font-style: italic;
    color: #666;
    margin-top: 5px;
    font-family: 'Lora', serif;
  }
  