/* src/css/Navbar.css */
.navbar-wrapper {
  position: relative;
}

.navbar-container {
  background-color: #f8f9fa;
  border-top: 0;
}

.navbar-container::before, .navbar-container::after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%; /* Ширина полоски по умолчанию */
  max-width: 1200px; /* Максимальная ширина полоски */
  border-top: 1px solid #ddd;
}

.navbar-container::before {
  top: 5px;
}

.navbar-container::after {
  bottom: 5px;
}

.navbar-content {
  max-width: 1200px;
  margin: 0 auto;
}

.custom-nav {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.navbar-wrapper .navbar-container .custom-nav .nav-link {
  background-color: rgba(0, 0, 0, 0);
  border-top-color: rgb(51, 51, 51);
  border-top-style: none;
  border-top-width: 0px;
  color: rgb(51, 51, 51);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Lora', serif;
  font-size: 16.884px;
  height: 58px;
  letter-spacing: normal;
  list-style-type: none;
  padding: 0 18px; /* Устанавливаем внутренние отступы */
  text-align: center;
  text-decoration: none;
  text-decoration-color: rgb(51, 51, 51);
  text-size-adjust: 100%;
  text-transform: uppercase;
  transition: color 0.3s ease, opacity 0.3s ease;
  white-space: nowrap;
}

.navbar-wrapper .navbar-container .custom-nav .nav-link.active {
  color: #800000;
  font-weight: bold;
}

.navbar-wrapper .navbar-container .custom-nav .nav-link:hover {
  color: #800000;
  opacity: 0.7;
}

.navbar-wrapper .navbar-container .custom-nav .language-switcher:hover {
  color: #0056b3; 
  opacity: 0.7; 
}

.container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-toggler {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
}

.navbar-toggler:focus, .navbar-toggler:hover .navbar-toggler:active{
  outline: none;
  box-shadow: none;
}