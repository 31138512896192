/* src/css/ScrollToTopButton.css */
.scroll-to-top {
    position: fixed;
    bottom: 40px;
    right: 40px;
    width: 50px;
    height: 50px;
    background-color: rgba(200, 200, 200, 0.8);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    z-index: 9999;
  }
  
  .scroll-to-top.visible {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  
  .scroll-to-top:hover {
    background-color: rgba(200, 200, 200, 1);
  }
  
  @media (max-width: 768px) {
    .scroll-to-top {
      display: none;
    }
  }