/* src/css/Biography.css */
.biography-container {
    margin: 2rem 0;
    font-family: 'Lora', serif;
    padding-bottom: 48px;
  }
  
  .biography-text-section {
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .biography-photo-frame {
    text-align: center;
    padding: 1rem;
    border-radius: 8px;
  }

  .biography_content {
    font-size: 1.2em;
    text-align: justify;
    line-height: 1.6;
  }
  
  .biography-photo {
    width: 100%;
    height: auto;
    max-width: 300px;
    border-radius: 8px;
    object-fit: cover;
  }
  
  .biography-sidebar {
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f2f2f2;
  }
  
  .biography-details {
    padding: 1rem;
  }
  
  .biography-sidebar h3, h4 {
    font-family: 'Source Serif Pro', serif;
    font-weight: 550;
  }
  
  .biography-sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .additional-links {
    margin-top: 3rem;
  }
  .profile-section {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Центрирование по вертикали */
    height: 100%;
  }

  .profile-section ul {
    margin-bottom: 0; 
    list-style-type: none;
    padding-left: 0;
  }
  
  .profile-section li {
    margin-bottom: 1rem;
  }

  @media (max-width: 991.98px) {
    .biography-text-section {
      border: none; /* Убираем рамку на малых экранах */
      padding: 0; /* Убираем отступы */
      padding-bottom: 20px;
    }
  }