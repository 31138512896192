/* src/css/Footer.css */
.footer-container {
    padding: 20px 0; /* Отступы сверху и снизу */
    position: relative; /* Для позиционирования полоски */
    font-size: 14.875px; /* Размер шрифта */
    color: rgb(17, 17, 17); /* Цвет текста */
  }
  
  .footer-container::before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%; /* Ширина полоски по умолчанию */
    max-width: 1200px; /* Максимальная ширина полоски */
    border-top: 1px solid #ddd;
    top: 0; /* Полоска сверху */
  }
  
  .footer-text {
    font-size: 14.875px;
    color: #abb8c3;

  }

  .footer-title-link {
    font-size: 14.875px;
    text-decoration: none;
    color: #abb8c3;
    font-weight: 550;
  }